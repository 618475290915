import { css } from '@emotion/react';

export default (theme) => css`
	.pricing-hero-container {
		background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(223, 227, 232, 1) 100%);
		padding-bottom: ${theme.spacing(4)};

		.multi-card-container {
			width: 100%;
			aspect-ratio: 1 / 1.1955;

			.multi-card-image {
				width: 100%;
				height: 100%;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				margin-top: ${theme.spacing(5)};
			}

		}
	}
`;
