import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.slider-wrapper {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	

	.slider {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;
		// top and bottom padding for inside slider cards grow effects
		padding-top: ${theme.spacing(4)};
		padding-bottom: ${theme.spacing(4)};
		margin-bottom: -${theme.spacing(4)};
		margin-top: -${theme.spacing(4)};
		padding-left: ${theme.spacing(4)};

		&::-webkit-scrollbar {	
			display: none;
		}
	}

	.slider-button {
		background-color: ${theme.palette.common.white};
		box-shadow: 0 3px 15px #0000000d;
		width: 60px;
		height: 60px;
		z-index: 1;

		${theme.breakpoints.down('md')} {
			display: none;
		}
	}

	.button-left {
		left: 1rem;
		top: 13.5rem;
	}

	.button-right {
		right: -75.5vw;
		top: -14rem;
	}

	${theme.breakpoints.up('lg')} {
		padding-left: calc((100vw - 1260px) / 2);
	}
`;
