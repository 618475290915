import React from 'react';
import styles from './styles';
import { Box, Container, useMediaQuery } from '@mui/material';
import Layout from '@components/common/Layouts/Landing';
import CompletePackage from '@components/Landing/Pricing/CompletePackage';
import USPSection from '@components/Landing/Pricing/USPSection';
import TabPanelReversed from '@components/Landing/Pricing/TabPanelReversed';
import JustifiedInfo from '@components/Landing/Pricing/JustifiedInfo';
import Hero from '@components/Landing/Pricing/Hero';
import JustifiedMobile from '@assets/pricing-mobile.png';
import JustifiedRecycling from '@assets/pricing-recycling.png';
import Dashboard from '@assets/pricing-dashboard@2x.png';
import CardPlant from '@assets/pricing-plant@2x.png';
import CardGrey from '@assets/pricing-card@2x.png';
import CardDesk from '@assets/pricing-desk@2x.png';
import TabFeature from '@components/Landing/Pricing/TabFeature';
import DarkCard from '@assets/pricing-dark.png';
import ForgetPaper from '@components/Landing/Pricing/ForgetPaper';
import CardSlider from '@components/Landing/Pricing/CardSlider';
import CardSliderItem from '@components/Landing/Pricing/CardSlider/CardSliderItem';
import { useTheme } from '@mui/styles';

const forgetPaperImages = [CardPlant, CardGrey, DarkCard, CardDesk];

const tabs = [
	{
		title: 'Dashboard',
		text: 'A platform to manage your card performance and a way to edit your LinkHub',
	},
	{
		title: 'LinkHub',
		text: 'The hub that your card links to. It’s completely customisable to your brand.',
	},
	{
		title: 'Statistics',
		text:
			'Track your own networking statistics gathered through your card, or a teams performance.',
	},
	{
		title: 'Read More',
		text: 'Coming soon.',
	},
];

const tabContent = [
	<TabFeature
		image={Dashboard}
		title="An efficient dashboard to manage your card"
		text="Everyone who buys and Wave Card gets access to our bespoke dashboard platform. You can manage your LinkHub, track your statistics or manage many different cards under one account."
	/>,
	<TabFeature
		image={Dashboard}
		title="Your networking hub"
		text="Every single connection you make using Wave Card gets sent here. It can be customised to suit your brand and accommodates a number of features, from displaying your social media links, to a preview of your portfolio."
	/>,
	<TabFeature
		image={Dashboard}
		title="Track your Wave Card"
		text="Track company-wide networking performance. See who is performing the best. Integrate your network recipients into your CRM system. Our statistic dashboard allows you to see an overview of how Wave Card is helping you."
	/>,
	<TabFeature image={Dashboard} title="Visit our knowledgebase" text="Coming soon." />,
];

const Pricing: React.FC = () => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div css={styles(theme)}>
			<Hero />
			<Container maxWidth="md" sx={{ textAlign: 'center', margin: 'auto' }}>
				<Box sx={{ mt: 10, mb: 15 }}>
					<ForgetPaper
						caption="Forget paper"
						title="The Wave Card represents the successful business-person that secures leads."
						text="Our card does not get thrown out, it gives your potential business associate a taster of the fruitful future relationship of success."
					/>
				</Box>
			</Container>
			<CardSlider>
				{forgetPaperImages?.map((img, i) => (
					<CardSliderItem key={i}>
						<img
							src={img}
							alt={`Wave card image ${i + 1}`}
							style={{
								width: '100%',
								height: '100%',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
							}}
						/>
					</CardSliderItem>
				))}
			</CardSlider>
			<Container maxWidth="md">
				<Box sx={{ mt: { xs: 10, lg: 20 } }}>
					<CompletePackage />
				</Box>
				<Box sx={{ mt: 10 }}>
					<USPSection />
				</Box>
				<Box sx={{ mt: { xs: 10, lg: 20 } }} className="justified-info-container">
					<JustifiedInfo
						title="More accessible than paper business cards"
						text="There are several attainable techniques for people with visual impairments to browse the modern web. As such our site is compatible with Apple’s iOS VoiceOver and Android TalkBack, allowing those users to communicate efficiently while using Wave Card."
						image={JustifiedMobile}
						imageAlt="Mobile accessibility"
					/>
					<JustifiedInfo
						title="Environmentally friendly compared to traditional cards"
						text="Our card is made out of Aluminium. The same material that cans are made out of and can be continually recycled. We do not provide to deforestation by producing disposable cards, our"
						image={JustifiedRecycling}
						imageAlt="Environmentally friendly disposable card image"
						reversed
					/>
				</Box>
			</Container>
			<Box sx={{ mt: 10 }} className="feature-toggle-container">
				<Container maxWidth="md">
					<TabPanelReversed tabs={tabs} tabContent={tabContent} />
				</Container>
			</Box>
		</div>
	);
};

export default () => (
	<Layout flush disableGutterTop>
		<Pricing />
	</Layout>
);
