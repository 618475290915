import { css } from '@emotion/react';

export default (theme) => css`

	${theme.breakpoints.down('lg')} {
		max-width: 800px;
		margin: auto;
		text-align: center;
	}

	.image-container {
		display: flex;
		justify-content: center;
		width: 100%;
		max-height: 500px;
		height: 100%;
		padding-top: ${theme.spacing(4)};
		.image {
			max-width: 450px;
			width: 100%;
			height: auto;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
`;
