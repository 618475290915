import React from 'react';

import styles from './styles';
import { useTheme } from '@mui/material';
import { Box } from '@mui/material';

const CardSliderItem: React.FC = ({ children }) => {
	const theme = useTheme();

	return <Box css={styles(theme)}>{children}</Box>;
};

export default CardSliderItem;
