import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import { Grid, Stack, Typography } from '@mui/material';

const TabFeature: React.FC<{ image: string; imageAlt?: string; title: string; text: string }> = ({
	image,
	imageAlt,
	title,
	text,
}) => {
	const theme = useTheme();
	return (
		<div css={styles(theme)}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<div className="image-container">
						<img src={image} alt={imageAlt} className="image" />
					</div>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Stack
						direction="column"
						spacing={2}
						sx={{ height: '100%', justifyContent: 'center' }}
					>
						<Typography variant="h2" display="block">
							{title}
						</Typography>
						<Typography variant="body1" display="block">
							{text}
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</div>
	);
};
export default TabFeature;
