import React, { useContext } from 'react';
import styles from './styles';
import PricingCard from '@components/Landing/Pricing/Hero/Card';
import { Box, Container, Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import HeroImage from '@assets/card-collection.svg';
import { HeaderHeightContext } from '@helpers/contexts';

const Hero: React.FC = () => {
	const theme = useTheme();
	const { height: headerHeight } = useContext(HeaderHeightContext);

	return (
		<div css={styles(theme)}>
			<Box sx={{ paddingTop: `${headerHeight}px` }} className="pricing-hero-container">
				<Container maxWidth="md">
					<Grid container spacing={2} justifyContent="start" alignItems="center">
						<Grid item xs={12} md={6}>
							<div className="multi-card-container">
								<img
									src={HeroImage}
									alt="wave cards stacked"
									className="multi-card-image"
								/>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div className="pricing-card-container">
								<PricingCard />
							</div>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};

export default Hero;
