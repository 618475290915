import React from 'react';
import { useTheme } from '@mui/material';
import styles from './styles';
import { Stack, Typography } from '@mui/material';

const ForgetPaper: React.FC<{ caption: string; title: string; text: string }> = ({
	caption,
	title,
	text,
}) => {
	const theme = useTheme();
	return (
		<div css={styles(theme)} style={{ maxWidth: '800px', width: '100%', margin: 'auto' }}>
			<Stack direction="column" spacing={2}>
				<Typography
					variant="caption"
					display="block"
					sx={{ color: theme.palette.primary.main }}
				>
					{caption}
				</Typography>
				<Typography variant="h2" display="block">
					{title}
				</Typography>
				<Typography variant="body1" display="block">
					{text}
				</Typography>
			</Stack>
		</div>
	);
};

export default ForgetPaper;
