import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material';
import styles from './styles';

const TabPanel: React.FC<{ value: number; index: number }> = ({ value, index, children }) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const TabPanelReversed: React.FC<{ tabs: []; tabContent: [] }> = ({ tabs, tabContent }) => {
	const theme = useTheme();
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			{tabContent?.map((content, index) => {
				return (
					<TabPanel value={value} index={index} key={index}>
						{content}
					</TabPanel>
				);
			})}
			<Box
				sx={{ borderTop: 1, borderColor: 'divider', mt: { xs: 8, sm: 8, md: 8 } }}
				css={styles(theme)}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="tabs switch"
					className="custom-tabs"
				>
					{tabs?.map((tab, index) => {
						const { title, text } = tab;
						return (
							<Tab
								{...a11yProps(index)}
								key={index}
								component={() => (
									<Stack
										direction="column"
										spacing={2}
										className="custom-tab"
										onClick={() => setValue(index)}
									>
										<Typography variant="h4" display="block">
											{title}
										</Typography>
										<Typography variant="body1" display="block">
											{text}
										</Typography>
									</Stack>
								)}
							/>
						);
					})}
				</Tabs>
			</Box>
		</Box>
	);
};

export default TabPanelReversed;
