import React, { useRef, useState, useEffect } from 'react';

import styles from './styles';
import { useTheme } from '@mui/material';
import { Box, ButtonBase } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Slider: React.FC<{ innerRef: any }> = ({ innerRef, children }) => {
	const theme = useTheme();
	const $slider = innerRef || useRef(null);
	const $leftBtn = innerRef || useRef(null);
	const $rightBtn = innerRef || useRef(null);

	const getWidth = () => {
		return window.innerWidth;
	};

	const [state, setState] = useState({
		translate: 0,
		transition: 0.45,
	});

	const { translate, transition } = state;

	const handleClick = (e) => {
		if (e.target.value === 'left' || e.target.classList.contains('button-left')) {
			$slider.current.scrollBy(-400, 0);
		} else if (e.target.value === 'right' || e.target.classList.contains('button-right')) {
			$slider.current.scrollBy(400, 0);
		}
	};

	return (
		<Box className="slider-wrapper" css={styles(theme)}>
			<Box
				sx={{
					borderRadius: '50%',
				}}
				onClick={(e) => handleClick(e)}
				component={ButtonBase}
				className="slider-button button-left"
				aria-label="leftBtn"
				value="left"
				ref={$leftBtn}
			>
				<ArrowLeftIcon
					fontSize="large"
					color="primary"
					className="button-icon button-left"
				/>
			</Box>
			<Box className="slider" ref={(slider) => ($slider.current = slider)}>
				{children}
			</Box>
			<Box
				sx={{
					borderRadius: '50%',
				}}
				onClick={(e) => handleClick(e)}
				component={ButtonBase}
				className="slider-button button-right"
				aria-label="rightBtn"
				value="right"
				ref={$rightBtn}
			>
				<ArrowRightIcon
					fontSize="large"
					color="primary"
					className="button-icon button-right"
				/>
			</Box>
		</Box>
	);
};

const CardSlider = React.forwardRef((props: any, ref) => (
	<Slider noPadding={props.noPadding} innerRef={ref} {...props}>
		{props.children}
	</Slider>
));

export default CardSlider;
