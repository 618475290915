import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import ComputerCogs from '@assets/computer-cogs.inline.svg';

const PricingHero: React.FC = () => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<div css={styles(theme)}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Stack
						direction="column"
						spacing={mobile ? 4 : 2}
						sx={{ justifyContent: 'center' }}
					>
						<Typography
							variant="caption"
							display="block"
							sx={{ color: theme.palette.primary.main }}
						>
							Wave Card is the complete package
						</Typography>
						<Typography variant="h2" display="block">
							The card is only a small step in an overhauled networking platform.
							Discover our LinkHub
						</Typography>
						<Typography variant="body1" display="block">
							When you buy one of our cards, you not only buy the physical card, but
							the complete web-software that drives a comprehensive networking
							experience.
						</Typography>
					</Stack>
				</Grid>
				<Grid item xs={12} lg={6}>
					<div className="image-container">
						<ComputerCogs className="image" />
					</div>
				</Grid>
			</Grid>
		</div>
	);
};
export default PricingHero;
