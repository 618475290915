import React, { useEffect, useState } from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import PaletteCard from '@components/Landing/common/PaletteCard';
import { useSnackbar } from '@components/common/Snackbar';
import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { CHECKOUT, CHECKOUTS } from '@helpers/api';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import { Checkout, CheckoutCardColour } from '@models/checkout';
import useDidUpdateEffect from '@helpers/hooks/useDidUpdateEffect';
import Loader from '@components/common/Loader';

const Card: React.FC = () => {
	const theme = useTheme();
	const [openSnackbar] = useSnackbar();
	const location = useLocation();
	const params = queryString.parse(location.search);
	const checkoutId: any = params.checkoutId;
	const [checkout, setCheckout] = useState<Checkout | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		(async () => {
			try {
				if (!checkoutId) {
					setLoading(false);
					return;
				}

				const { data: checkoutData } = await makeGetRequest(CHECKOUT(checkoutId));
				setCheckout(checkoutData);
				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ?? 'An error occurred attempting to get your checkout.',
						'error'
					);
					setCheckout(null);
					setLoading(false);
				}
			}
		})();
	}, []);

	const initialValues: {
		colour: CheckoutCardColour | string;
	} = {
		colour: checkout?.card?.colour ?? '',
	};

	const validationSchema = Yup.object().shape({
		colour: Yup.string().required('Colour is required').label('Colour'),
	});

	const handleSubmit = async () => {
		console.log({ checkout });
		if (!checkout) return;

		navigate(
			`/checkout/?${queryString.stringify({
				...params,
				checkoutId: checkout?._id,
			})}`
		);
	};

	return (
		<div css={styles(theme)}>
			<Paper elevation={0} className="pricing-card">
				<Stack spacing={3}>
					<Typography className="pricing-card-caption" variant="caption" display="block">
						Material
					</Typography>
					<Typography className="pricing-card-heading" variant="h2" display="block">
						316 Brushed Stainless Steel
					</Typography>

					{loading ? (
						<Loader />
					) : (
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{({ isSubmitting, setFieldValue, values }) => {
								const [saving, setSaving] = useState(false);

								useDidUpdateEffect(() => {
									(async () => {
										try {
											setSaving(true);
											const { data: checkoutData } = !!checkout
												? await makePutRequest(CHECKOUT(checkout?._id), {
														colour: values?.colour || undefined,
												  })
												: await makePostRequest(CHECKOUTS, {
														colour: values?.colour || undefined,
												  });

											if (!checkout) {
												navigate(
													`?${queryString.stringify({
														...params,
														checkoutId: checkoutData._id,
													})}`,
													{ replace: true }
												);
											}

											setCheckout(checkoutData);
											setSaving(false);
										} catch (error) {
											if (error !== 'cancelled') {
												openSnackbar(
													error?.errorMessage ??
														`Unable to ${
															!!checkout ? 'update' : 'create'
														} checkout.`,
													'error'
												);
												setSaving(false);
											}
										}
									})();
								}, [values]);

								return (
									<Stack spacing={2}>
										<Typography
											className="pricing-card-caption"
											variant="caption"
											display="block"
											sx={{ mt: 3 }}
										>
											Finish
										</Typography>
										<Typography
											className="pricing-card-heading"
											variant="h2"
											display="block"
										>
											{values?.colour}
										</Typography>
										<Typography
											className="pricing-card-subtitle"
											variant="subtitle1"
											display="block"
										>
											Durable electroplated finish
										</Typography>
										<Form className="form">
											<Grid container spacing={2}>
												<Grid item xs={4} sm={4} md={4} lg={4}>
													<PaletteCard
														onSelected={() =>
															setFieldValue('colour', 'black')
														}
														colour={'black'}
														selected={
															(values?.colour ||
																null) as CheckoutCardColour
														}
													/>
												</Grid>
												<Grid item xs={4} sm={4} md={4} lg={4}>
													<PaletteCard
														onSelected={() =>
															setFieldValue('colour', 'silver')
														}
														colour={'silver'}
														selected={
															(values?.colour ||
																null) as CheckoutCardColour
														}
													/>
												</Grid>
												<Grid item xs={4} sm={4} md={4} lg={4}>
													<PaletteCard
														onSelected={() =>
															setFieldValue('colour', 'gold')
														}
														colour={'gold'}
														selected={
															(values?.colour ||
																null) as CheckoutCardColour
														}
													/>
												</Grid>
											</Grid>
											<Stack spacing={2} mt={5}>
												<Typography
													className="pricing-card-caption"
													variant="caption"
													display="block"
												>
													Pre-release alpha price
												</Typography>
												<Stack
													className="pricing-card-price"
													direction="row"
													alignItems="flex-end"
												>
													<Typography
														className="pricing-card-heading pricing-card-price-new"
														variant="h2"
													>
														£29.99
													</Typography>
													/
													<Typography
														className="pricing-card-subtitle pricing-card-price-old"
														variant="subtitle1"
													>
														£39.99
													</Typography>
												</Stack>
												<Button
													type="submit"
													className="submit"
													fullWidth
													variant="contained"
													color="primary"
													disabled={isSubmitting || saving}
													sx={{ mt: 3 }}
													startIcon={
														isSubmitting ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : undefined
													}
												>
													Create & buy
												</Button>
											</Stack>
										</Form>
									</Stack>
								);
							}}
						</Formik>
					)}
				</Stack>
			</Paper>
		</div>
	);
};

export default Card;
