import { css } from '@emotion/react';

export default (theme) => css`
	.custom-tabs {
		.MuiTabs-indicator {
			top: 0;
		}

		.custom-tab {
			padding: 20px;
			width: 300px;

			h4 {
				margin: 0 0 20px;
			}

			p {
				margin-top: 0;
				white-space: normal;
			}
		}
	}
`;
