import { css } from '@emotion/react';

export default (theme) => css`
	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: auto;
		aspect-ratio: 16/9;
		.image {
			width: 100%;
			height: auto;
			max-width: 700px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
`;
