import { css } from '@emotion/react';

export default (theme) => css`
	.pricing-card {
		box-shadow: 2px 3px 20px #00000029;
		border-radius: 1rem;
		padding: ${theme.spacing(5)} ${theme.spacing(7)};
		padding-top: ${theme.spacing(7)};
	}

	.pricing-card-caption {
		color: ${theme.palette.primary.main};
		letter-spacing: 0.08rem;
		font-weight: 400;
		font-size: 0.7rem;
	}

	.pricing-card-heading {
		margin-top: ${theme.spacing(1)};
		font-size: 1.4rem;
		font-weight: 800;
		text-transform: capitalize;
	}

	.pricing-card-subtitle {
		text-transform: capitalize;
		font-weight: 400;
		font-size: 0.8rem;
		margin-top: ${theme.spacing(1)};
	}

	.pricing-card-price {
		margin-top: ${theme.spacing(0)};
		font-size: 1rem;
	}

	.pricing-card-price-new {
		font-size: 1.8rem;
		padding-right: ${theme.spacing(0.2)};
	}

	.pricing-card-price-old {
		text-decoration: line-through;
		padding-bottom: ${theme.spacing(0.1)};
	}

	${theme.breakpoints.down('sm')} {
		.pricing-card {
			padding: ${theme.spacing(4)} ${theme.spacing(3)};
		}

		.pricing-card-heading {
			margin-top: ${theme.spacing(1)};
		}

		.pricing-card-price {
			font-size: 0.7rem;
		}
	}
`;
