import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.feature-toggle-container {
		background-color: #161c24;
		color: ${theme.palette.common.white};
		padding: 0 0 ${theme.spacing(6)};
	}

	${theme.breakpoints.down('md')} {
		.justified-info-container {
			.css-152hlj-JustifiedInfo:last-of-type {
				margin-top: ${theme.spacing(1)};
			}

			text-align: center;
			max-width: 700px;
			margin: auto;
			margin-top: ${theme.spacing(10)};
		}
	}
`;
