import React from 'react';
import { useTheme } from '@mui/material';
import styles from './styles';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

const JustifiedInfo: React.FC<{
	title: string;
	text: string;
	image: string;
	imageAlt: string;
	reversed?: boolean;
}> = ({ title, text, image, imageAlt, reversed }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<div css={styles(theme)}>
			<Grid
				container
				spacing={mobile ? 4 : 2}
				direction={reversed ? 'row-reverse' : 'row'}
				justifyContent="center"
				alignItems="center"
			>
				<Grid item xs={12} md={6}>
					<Stack direction="column" spacing={4}>
						<Typography variant="h2" display="block">
							{title}
						</Typography>
						<Typography variant="body1" display="block">
							{text}
						</Typography>
					</Stack>
				</Grid>
				<Grid item xs={12} md={6}>
					<div className="image-container">
						<img src={image} alt={imageAlt} className="image" />
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default JustifiedInfo;
