import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	flex: 0 0 auto;
	width: 85%;
	height: inherit;
	padding-right: ${theme.spacing(4)};

	${theme.breakpoints.up('sm')} {
		width: 55%;
	}

	${theme.breakpoints.up('md')} {
		width: 40%;
	}

	${theme.breakpoints.up('lg')} {
		width: 30%;
	}

	${theme.breakpoints.up('xl')} {
		width: 22.5%;
	}

	> * {
		height: 100%;
		margin-bottom: 0;
	}

	> img {
		width: 100%;
		height: auto;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
`;
