import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import USPCard from '@components/AffiliateUSPCard';
import Plus from '@assets/plus.inline.svg';
import Construction from '@assets/construction.inline.svg';
import Cheque from '@assets/check.inline.svg';
import Money from '@assets/money-envelope.inline.svg';
import { useTheme } from '@mui/material';
import styles from './styles';

const USPSection: React.FC = () => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid container spacing={6} justifyContent="center" alignItems="center" css={styles(theme)}>
			<Grid item md={3} sm={12}>
				<USPCard
					svg={<Plus />}
					title="Lifetime Warranty"
					description="Add a special link to your LinkHub that allows you to naturally lead people to your affiliate link."
				/>
			</Grid>
			<Grid item md={3} sm={12}>
				<USPCard
					svg={<Construction />}
					title="Customisable LinkHub"
					description="As a highly valued member of our team, you will gain the upper edge by being the earliest to getting our new features."
				/>
			</Grid>
			<Grid item md={3} sm={12}>
				<USPCard
					svg={<Cheque />}
					title="Network Tracking"
					description="At only 5 referrals before your withdrawal, you can be assured you will be paid for your hard work."
				/>
			</Grid>
			<Grid item md={3} sm={12}>
				<USPCard
					svg={<Money />}
					title="Fast Delivery"
					description="Expect payment between one to three business days."
				/>
			</Grid>
		</Grid>
	);
};

export default USPSection;
