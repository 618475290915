import { css } from '@emotion/react';

export default (theme) => css`

	${theme.breakpoints.up('md')} {
		h3 {
			font-size: 1.5rem;
		}
	}
`;
